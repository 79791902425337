@import "styles/theme.scss";
.item {
  max-width: 220px;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;

  &Image {
    margin-bottom: 12px;
  }
}
