@import "styles/theme.scss";
$background: white;

.card {
  background: $background;

  border-radius: $border-radius-s;

  box-shadow: $shadow-main;

  &Size {
    &_XS {
      padding: 9px 27px;

      border-radius: 0;
    }

    &_S {
      padding: 32px;
    }

    &_M {
      padding: 48px;
    }
  }
}
