$primary-ultra-light-color: #bde3ff;
$select-border-color: #e2e2e3;
$test-item-color: #9dedff;
$accent-color: #00d1ff;
$primary-color: #003251;
$secondary-color: #8fa8c0;
$grey-color: #6d6e71;
$grey-light-color: #818ba7;
$light-color: #e3e3e3;
$light-blue-color: #f1f7fc;
$default-right-color: #1482fa;
$wrong-color: #ff1f26;
$success-color: #00bd5d;
$invisible-color: rgba(0, 0, 0, 0);

$alert-error-close: #ba2525;
$alert-success-close: #3f9142;

$shadow: 10px 9px 36px 10px rgba($secondary-color, 0.5);
$shadow-test: 0 3px 5px rgba(138, 170, 219, 0.5);
$shadow-main: $shadow-test, 0 6px 16px rgba(138, 170, 219, 0.3);

$font-size-xxs: 12px;
$font-size-xs: 15px;
$font-size-s: 18px;
$font-size-m: 24px;
$font-size-l: 32px;
$font-size-xl: 50px;
$font-size-xxl: 200px;

$input-radius: 10px;
$border-radius: 12px;
$border-radius-s: 6px;
$border-radius-xs: 4px;

$border-primary: 1px solid rgba($grey-light-color, 0.35);

$font-family: 'Montserrat', sans-serif;

$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$mobile-s: 320px;
$mobile-m: 375px;
$mobile-l: 425px;
$tablet: 768px;
$laptop: 1024px;
$laptop-height: 1366px;
$laptop-l: 1440px;

@mixin input {
  position: absolute;
  top: 21px;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 52px;
  background-color: $background-color;
  border: unset;
  border-bottom: 2px solid $secondary-color;
  border-radius: $input-radius;
  outline-color: $light-color;
  font-size: $font-size-xxs;
  line-height: 1.3;
  transition: 0.4s;
}

@mixin input-focus {
  font-family: inherit;
  resize: none;
  outline: inherit;
}

@mixin label {
  position: relative;
  display: block;
  font-size: $font-size-xxs;
  line-height: 1.3;
  width: 100%;
}

@mixin input-error {
  box-shadow: inset 0 0 0 1px $wrong-color;
  border-bottom: 2px solid $wrong-color;
}

@mixin label-title {
  display: block;
  margin-bottom: 4px;
  font-weight: $font-weight-semi-bold;
  width: 100%;
}

%message {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  display: block;
  padding: 16px 16px 3px; 
}

@mixin error-message {
  @extend %message;

  color: $wrong-color;
  font-size: $font-size-xxs;
  background-color: $wrong-color;
  color: white;
  border-bottom-left-radius: $input-radius;
  border-bottom-right-radius: $input-radius;
  opacity: 0;
  transform: translateY(-5px);
  transition: 0.4s;
}

@mixin description-message {
  @extend %message;

  font-size: $font-size-xxs;
  background-color: var(--primary-accent-color);
  border-bottom-left-radius: $input-radius;
  border-bottom-right-radius: $input-radius;
  opacity: 0;
  transform: translateY(-5px);
  transition: 0.4s;
}

@mixin message-show-animation {
  opacity: 1;
  transform: translateY(0);
}

@mixin input-valid {
  border-bottom-color: $grey-light-color;
}

@mixin user-select-allowed {
  * {
    user-select: text;
  }
}
