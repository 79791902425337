@import "styles/theme.scss";
.wrapper {
  position: relative;
  overflow-y: auto;
  max-height: 100%;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 16px;
  row-gap: 41px;
  padding: 16px 32px;
}
